<template>
  <div class="work-flow">
    <p class="title">服务流程</p>

    <div class="item-list container">
      <div>
        <div>
          <img src="../../assets/img/flow-communicate.png" alt="沟通需求">

          <h4>沟通需求</h4>
        </div>
        <p>用户需求沟通，提出建议，明确方案，上线规划。</p>
      </div>

      <div>
        <div>
          <img src="../../assets/img/flow-prototype-design.png" alt="原型交互设计">
          <h4>原型交互设计</h4>
        </div>
        <p>根据文档进行整体项目页面框架搭建，以原型图的方式展示。</p>
      </div>


      <div>
        <div>
          <img src="../../assets/img/flow-ui-design.png" alt="视觉UI设计">

          <h4>视觉UI设计</h4>
        </div>
        <p>首页体现项目风格的重要页面，进行板式规划、色彩搭配、整体设计。</p>
      </div>

      <div>
        <div>
          <img src="../../assets/img/flow-coding.png" alt="前后端代码开发">
          <h4>前后端代码开发</h4>
        </div>
        <p>前后端代码架构设计、代码编写、联调。</p>
      </div>

      <div>
        <div>
          <img src="../../assets/img/flow-test.png" alt="测试反馈">
          <h4>测试反馈</h4>
        </div>
        <p>交付版本，上传内容，反馈收集，优化体验。</p>
      </div>

      <div>
        <div>
          <img src="../../assets/img/flow-running.png" alt="上线运营维护">
          <h4>上线运营维护</h4>
        </div>

        <p>部署到线上生产环境、售后技术维护。</p>
      </div>


    </div>

  </div>
</template>

<script>
export default {
  name: 'WorkFlowLayer'
}
</script>

<style scoped>
.work-flow {
  background-color: #f7f7f7;
  padding: 100px 0;
}

.title {
  font-size: 40px;
  color: #F42727;
  margin-bottom: 50px;
  text-align: center;
}

.item-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item-list > div {
  width: 360px;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 30px;
  text-align: center;
  font-family: 'Viga', sans-serif;
}

.item-list > div h4 {
  font-size: 25px;
  font-weight: normal;
  padding: 20px 0;
  color: #FF7373;
}

.item-list > div p {
  color: #999999;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  padding: 0 30px;
}

@media screen and (max-width: 576px) {
  .item-list > div {
    margin-top: 15px;
  }

  .item-list > div > div {
    text-align: left;
  }

  .item-list > div img {
    width: 26px;
    height: 26px;
    display: inline-block;
    vertical-align: middle;
  }

  .item-list > div h4 {
    display: inline-block;
    margin-left: 10px;
    padding: 0;
    line-height: 26px;
    height: 26px;
    vertical-align: middle;
    font-size: 18px;
  }

  .item-list > div p {
    padding: 0;
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;
  }
  .work-flow{
    padding: 30px 0;
  }
  .title{
    font-size: 24px;
    margin-bottom: 15px;
  }
}
</style>