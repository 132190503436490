<template>
  <div class="wrap">
    <head-layer/>

    <div class="container">
      <div class="nav">
        <my-nav style="min-height: calc(100vh - 191px)"></my-nav>
      </div>
      <div class="content">
        <router-view style="padding: 20px"></router-view>
      </div>
      <div class="clear"></div>
    </div>
    <foot-layer/>
  </div>
</template>

<script>
  import MyNav from './MyNav'
  import HeadLayer from '../home/HeadLayer'
  import FootLayer from '../home/FootLayer'

  export default {
    name: 'ColumnTwo.vue',
    components: {FootLayer, HeadLayer, MyNav}
  }
</script>

<style scoped>
  .wrap {
    padding-top: 80px;
  }

  .nav {
    float: left;
    width: 200px;
  }

  .content {
    float: right;
    height: 100%;

    width: 1000px;
  }


</style>