<template>
  <div id="top-layer">
    <el-carousel >
      <el-carousel-item v-for="(pic,index) in pics" :key="index">
        <div :style="'background: url('+pic+') no-repeat 0 bottom;   background-size: cover;' " class="banner">

        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'TopLayer',
  data () {
    return {
      pics: [
        'static/banner-1.png',
        'static/banner-2.png',
        'static/banner-3.png',
        'static/banner-4.png'
      ]
    }
  }
}
</script>

<style scoped>
.banner {
  height: 100%;
}

</style>