<template>
  <div>
    <div class="right-side-bar">


      <el-tooltip placement="left">
        <div slot="content">
          客服QQ：822313023
        </div>
        <a href="javascript:" class="qq" title="点击联系"><i></i></a>
      </el-tooltip>

      <el-tooltip placement="left">
        <div slot="content">
          <div class="wechat-content">
            <img src="../../assets/img/wechat.jpg">
            <span>微信扫一扫联系客服</span>
          </div>
        </div>
        <a href="javascript:" class="wechat"><i></i></a>
      </el-tooltip>


      <a href="javascript:" v-show="scrollVisible" class="gotop" title="返回顶部" @click="gotop"><i></i></a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RightSideBar',
    data () {
      return {
        scrollVisible: false
      }
    },
    mounted () {
      const self = this
      window.addEventListener('scroll', function () {
        self.scrollVisible = document.documentElement.scrollTop > 100
      })
    },
    methods: {
      gotop () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  }
</script>

<style scoped>
  .right-side-bar {
    position: fixed;
    right: 0;
    top: 50%;
    width: 54px;
    height: 162px;
    margin-top: -81px;
    z-index: 9999;
  }

  .right-side-bar > a {
    background: rgb(34, 178, 204);
    width: 54px;
    height: 54px;
    display: block;
  }

  .right-side-bar > a:hover {
    background: rgb(54, 72, 96);
  }

  .right-side-bar i {
    vertical-align: text-top;
    background-image: url(../../assets/img/icon-list.png);
    height: 30px;
    margin: 12px;
    width: 30px;
    display: inline-block;
  }

  .qq i {
    background-position: 30px -40px;
  }

  .wechat i {
    background-position: 30px -80px;
  }

  .gotop i {
    background-position: 30px -264px;
  }

  .wechat-content > img {
    width: 160px;
    height: 160px;
    display: block;
  }

  .wechat-content > span {
    text-align: center;
    display: block;
    margin-top: 6px;
    line-height: 24px;
    font-size: 14px;
  }
</style>