<template>
  <div class="footer text-center">
    <p class="desc">
      版权所有
      <span class="copyright">©</span>
      2024 重庆沃古软件有限公司

    </p>
    <div class="desc mt10">
      <a href="http://beian.miit.gov.cn" target="_blank">备案号：渝ICP备2023017681号-1</a>


      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010702505589"
         class="gongan">
        <img src="../../assets/img/gongan.png" alt="渝公网安备"/>
        渝公网安备50010702505589号</a>
    </div>


  </div>
</template>

<script>
export default {
  name: 'FootLayer'
}
</script>

<style scoped>
.footer {
  background-color: #303740;
  color: #ffffff;
  padding: 30px 0;
}

a, a:hover {
  margin-left: 10px;
}

.copyright {
  font-family: arial;
  vertical-align: middle;
}


.desc, a, a:hover, a:visited {
  color: #9a9a9a;
}

.gongan img {
  vertical-align: middle;
  width: 16px;
  height: 16px;
}
</style>