<template>
  <el-menu
          :default-active="activeIndex"
  >

    <el-menu-item index="1" @click="goto('projectList')">
      <i class="el-icon-folder"></i>
      <span slot="title">我的项目</span>
    </el-menu-item>


    <el-menu-item index="2" @click="goto('payOrderList')">
      <i class="el-icon-tickets"></i>
      <span slot="title">支付订单</span>
    </el-menu-item>

    <el-menu-item index="3" @click="goto('invoiceList')">
      <i class="el-icon-printer"></i>
      <span slot="title">发票记录</span>
    </el-menu-item>

    <el-menu-item index="4" @click="goto('memberInfo')">
      <i class="el-icon-user"></i>
      <span slot="title">账号信息</span>
    </el-menu-item>

    <el-menu-item index="5" @click="goto('updatePassword')">
      <i class="el-icon-ext iconfont iconmima"></i>
      <span slot="title">密码设置</span>
    </el-menu-item>

    <el-menu-item index="6" @click="quit">
      <i class="el-icon-remove-outline"></i>
      <span slot="title">安全退出</span>
    </el-menu-item>

  </el-menu>
</template>

<script>
  export default {
    name: 'MyNav',
    data () {
      return {
        loading: null
      }
    },
    computed: {
      activeIndex () {
        const map = {
          project: '1',
          payOrder: '2',
          invoice: '3',
          memberInfo: '4',
          updatePassword: '5'
        }
        for (let key in map) {
          if (this.$route.name.startsWith(key)) {
            return map[key]
          }
        }
        return '1'
      }
    },
    methods: {
      goto (name) {
        if (this.$route.name !== name) {
          this.$router.push({name})
        }
      },
      quit () {
        this.loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        const self = this
        self.$http.post({
          url: '/auth/logout.action',
          success () {
            self.loading.close()
            self.$router.push({name: 'home'})
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>