import HomeIndex from './components/home/Index'
import ColumnTwo from './components/layouts/ColumnTwo'
import PopPanel from './components/layouts/PopPanel'

const AuthLogin = resolve => require(['@/components/auth/AuthLogin'], resolve)
const AuthRegister = resolve => require(['@/components/auth/AuthRegister'], resolve)
const AuthForget = resolve => require(['@/components/auth/AuthForget'], resolve)
const MemberIndex = resolve => require(['@/components/member/MemberIndex'], resolve)
const MemberInfo = resolve => require(['@/components/member/MemberInfo'], resolve)
const UpdatePassword = resolve => require(['@/components/member/UpdatePassword'], resolve)
const InvoiceList = resolve => require(['@/components/invoice/InvoiceList'], resolve)
const ProjectList = resolve => require(['@/components/project/ProjectList'], resolve)
const ProjectCreate = resolve => require(['@/components/project/ProjectCreate'], resolve)
const ProjectDetail = resolve => require(['@/components/project/ProjectDetail'], resolve)
const ProjectUpdate = resolve => require(['@/components/project/ProjectUpdate'], resolve)
const PayOrderList = resolve => require(['@/components/payOrder/PayOrderList'], resolve)
const CreatePayOrder = resolve => require(['@/components/payOrder/CreatePayOrder'], resolve)

export default {
  linkActiveClass: 'active',
  routes: [
    {
      name: 'home', path: '/', component: HomeIndex
    },
    {
      path: '/auth',
      component: PopPanel,
      children: [
        {name: 'authLogin', path: 'login', component: AuthLogin},
        {name: 'authRegister', path: 'register', component: AuthRegister},
        {name: 'authForget', path: 'forget', component: AuthForget}
      ]
    }, {
      path: '/member',
      component: ColumnTwo,
      children: [
        {name: 'memberIndex', path: 'index', component: MemberIndex},
        {name: 'memberInfo', path: 'info', component: MemberInfo},
        {name: 'updatePassword', path: 'updatePassword', component: UpdatePassword},
        {name: 'invoiceList', path: 'invoice/list', component: InvoiceList},
        {name: 'projectList', path: 'project/list', component: ProjectList},
        {name: 'projectCreate', path: 'project/create', component: ProjectCreate},
        {name: 'projectDetail', path: 'project/detail/:id', component: ProjectDetail},
        {name: 'projectUpdate', path: 'project/update/:id', component: ProjectUpdate},
        {name: 'payOrderList', path: 'payOrder/list', component: PayOrderList},
        {name: 'createPayOrder', path: 'payOrder/create/:id', component: CreatePayOrder},
      ]
    }
  ]
}