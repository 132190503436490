<template>
  <div class="service-layer">
    <h1 class="text-center">我们能做什么？</h1>
    <div class="service-list container">
      <div class="service">
        <img src="../../assets/img/service-pc.png" alt="pc建站"/>
        <div>
          <h2>网站建设</h2>
          <p>网站建设综合服务，提供一对一定制建站方案</p>
        </div>
      </div>

      <div class="service">
        <img src="../../assets/img/service-app.png" alt="APP开发"/>
        <div>
          <h2>APP开发</h2>
          <p>IOS、安卓APP定制开发</p>
        </div>
      </div>

      <div class="service">
        <img src="../../assets/img/service-wx.png" alt="小程序开发"/>
        <div>
          <h2>小程序开发</h2>
          <p>微信小程序、微信公众号开发</p>
        </div>
      </div>

      <div class="service">
        <img src="../../assets/img/service-erp.png" alt="ERP开发"/>
        <div>
          <h2>ERP/OA</h2>
          <p>为企业员工及决策层提供决策手段的管理平台</p>
        </div>
      </div>

      <div class="service">
        <img src="../../assets/img/service-desktop.png" alt="软件开发"/>
        <div>
          <h2>软件定制开发</h2>
          <p>为客户提供全面专业的软件定制开发服务</p>
        </div>
      </div>

      <div class="service">
        <img src="../../assets/img/service-browser-extension.png" alt="软件开发"/>
        <div>
          <h2>浏览器插件</h2>
          <p>chrome、firefox浏览器扩展程序定制开发</p>
        </div>
      </div>



    </div>
  </div>
</template>

<script>
  export default {
    name: 'ServiceLayer'
  }
</script>

<style scoped>

  .service-layer{
    padding: 100px 0;
  }


  h1{
    font-weight: normal;
    font-size: 40px;
    margin-bottom: 60px;
  }

  .service-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .service {
    margin-top: 30px;
    width: 360px;
    cursor: pointer;
    padding: 40px 20px;
    text-align: center;
    box-sizing: border-box;
  }

  .service  img {
    margin-bottom: 50px;
    height: 90px;
  }

  .service:hover {
    box-shadow: 0 0 10px rgba(23, 168, 254, 0.08), 0 5px 50px rgba(23, 168, 254, 0.08), 5px 0 100px rgba(23, 168, 254, 0.08);
  }

  .service  h2 {
    font-weight: bolder;
    line-height: 60px;
    font-size: 24px;
    color: #333333;
  }

  .service  p {
    color: #7e8296;
    height: 33px;
    line-height: 33px;
    font-size: 16px;
  }

  @media screen and (max-width: 576px) {
    .service{
      display: flex;
      margin-top: 15px;
      padding:10px 20px;
    }
    .service img{
      width: 40px;
      height: 40px;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .service > div{
      padding-left: 10px;
      text-align: left;
    }
    .service h2{
      font-size: 14px;
      line-height: 22px;
    }
    .service p{
      font-size: 12px;
      margin-top: 2px;
      line-height: 14px;
    }
    h1{
      font-size: 24px;
      margin-bottom: 20px;
    }
    .service-layer{
      padding: 30px 0;
    }
  }

</style>