import HttpRequest from 'tdw-http-request'

class Http extends HttpRequest {

  constructor () {
    super();
    this.$message = {}
  }

  defaultHeaders () {
    return {
      _token: localStorage.accessToken
    }
  }

  filter (response) {
    if (response.code === '10') {
      window.location.href = '/#/auth/login?from=' + encodeURIComponent(window.location.href)
      return false
    }
    return response.code === '00'
  }

  baseUrl () {
    return '/api'
  }


  defaultRequestType () {
    return 'formData'
  }

  errorHandle (response) {
    const error = this.getError(response)
    this.$message.error(error)
  }

  getError (response) {
    if (response.errors && response.errors.length !== 0) {
      for (let i in response.errors) {
        return response.errors[i]
      }
    } else {
      return response.msg || '网络错误'
    }
  }
}


const http = new Http()
export default http