<template>

  <div class="index">
    <head-layer/>
    <top-layer/>
    <service-layer/>
    <work-flow-layer/>
    <foot-layer/>
    <right-side-bar/>
  </div>
</template>

<script>
  import TopLayer from './TopLayer'
  import ServiceLayer from './ServiceLayer'
  import RightSideBar from './RightSideBar'
  import WorkFlowLayer from './WorkFlowLayer'
  import FootLayer from './FootLayer'
  import HeadLayer from './HeadLayer'

  export default {
    name: 'Index',
    components: {HeadLayer, FootLayer, WorkFlowLayer, RightSideBar, ServiceLayer, TopLayer}
  }
</script>

<style scoped>
  .index {
    padding-top: 80px;
  }



</style>