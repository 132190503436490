<template>
  <div class="header">
    <div class="container relative">
      <a class="logo" href="/">
        <img src="../../assets/img/logo.png" width="40" height="40"/>
        沃古软件
      </a>
      <div class="nav">
        <router-link :to="{path:'/'}" :class="linkClass">客户中心</router-link>
<!--        <router-link :to="{name:'memberIndex'}" :class="linkClass">客户中心</router-link>-->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeadLayer',
    computed: {
      linkClass () {
        return this.$route.name !== 'home' ? 'active' : ''
      }
    }
  }
</script>

<style scoped>
  .header {
    position: fixed;
    height: 80px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 4px 10px 0 rgba(228, 228, 228, 0.50);
    z-index: 999;
    top: 0;
  }

  .logo img {
    vertical-align: middle;
  }

  .logo {
    display: block;
    color: #3e5a18;
    font-size: 24px;
    position: absolute;
    left: 0;
  }

  .logo:hover {
    text-decoration: none;
  }

  .nav, .logo {
    height: 80px;
    line-height: 80px;

  }

  .nav {
    position: absolute;
    right: 0;
  }

  .nav a {
    display: inline-block;
    margin-left: 40px;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    position: relative;
  }

  .nav a:hover, .nav a.active {
    color: #7CBC26;
    text-decoration: none;
  }

  .nav a:hover:after, .nav a.active:after {
    content: '';
    top: auto;
    bottom: 0;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    height: 3px;
    background-color: #7CBC26;
    transition: all .2s;
  }
</style>